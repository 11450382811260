<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Toolbar from "./toolbar";
import Sidepanel from "./sidepanel";
import { common } from '@/mixins/common'

import { emailData } from "./data-inbox";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../../helpers/apiActions";
import moment from "moment-timezone";

/**
 * Email-inbox component
 */
export default {
  page: {
    title: "Inbox",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Toolbar,
    Sidepanel
  },
  data() {
    return {
      emailData: emailData,
      data: [],
      messages: [],
      paginatedEmailData: emailData,
      onPageTabLoad: true,
      title: "Messages",
      tab: '',
      inboxTabs: ['inbox', 'order', 'offer', 'question'],
      tabs: [
        {
          value: 'General',
          link: 'inbox',
          icon: 'fas fa-inbox',
          active: false,
          count: 0
        },
        {
          value: 'Orders',
          link: 'order',
          icon: 'fas fa-shopping-basket',
          active: false,
          count: 0
        },
        {
          value: 'Offers',
          link: 'offer',
          icon: 'fas fa-bullhorn',
          active: false
        },
        {
          value: 'Questions',
          icon: 'fas fa-question',
          link: 'question',
          active: false,
          count: 0
        },
      ],
      items: [
        {
          text: "Email",
          href: "/"
        },
        {
          text: "Inbox",
          active: true
        }
      ],
      // page number
      currentPage: 1,
      // default page size
      perPage: 15,
      emailIds: [],
      // start and end index
      startIndex: 1,
      endIndex: 15,
      conversationListSectionHeight: 300,
      pageLoaded: false
    };
  },
  computed: {
    rows() {
      return this.emailData.length;
    }
  },
  mixins: [
    common
  ],
  created() {
    this.startIndex = 0;
    this.endIndex = this.perPage;
    this.paginatedEmailData = this.emailData.slice(
      this.startIndex,
      this.endIndex
    );
  },
  mounted() {
    const _vm = this
    _vm.tab = _vm.$route.path.split('/')[2] || 'inbox'
    let findActiveTabIndex = _vm.tabs.findIndex(m=>m.link==_vm.tab) || 0
    _vm.$set(_vm.tabs, findActiveTabIndex, Object.assign(_vm.tabs[findActiveTabIndex], {
      active: true
    }));
    _vm.loadMessages({
      tab: _vm.tab
    })
    //
    _vm.conversationListSectionHeight = window.screen.height - 380
    document.getElementsByClassName('page-content')[0].style.paddingBottom = '0px'
  },
  methods: {
    onMessageLoad() {
      // eslint-disable-next-line
      console.log('loaded')
      let iframe = document.getElementById('iframe-content')
      iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    },
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      this.paginatedEmailData = this.emailData.slice(
        this.startIndex,
        this.endIndex
      );
    },
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/mail/' + tab
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    },
    loadMessages(params = {}) {
      let _vm = this
      const queryParams = _vm.getUrlParams(),
        filter = {
          ... queryParams,
          ... params
        }
      return PostApiWithAuthorizationAction("admin/messages/all", {
        filter
      }).then(res => {
        _vm.data = res.data
        _vm.pageLoaded = true
      })
    },
    loadConversationMessages(id) {
      const _vm = this
      return GetApiActionWithAuthorization("admin/messages/get-messages/" + id).then(res => {
        _vm.messages = res.data
      })
    },
    getStringFromHTML(html){
      // Create a new div element
      var tempDivElement = document.createElement("html");
      // Set the HTML content with the given value
      tempDivElement.innerHTML = html;
      // Retrieve the text property of the element 
      return tempDivElement.textContent || tempDivElement.innerText || "";
    },
    getFormattedDate(timestamp) {
      return moment(timestamp).format('MMM, D')
    },
    handleConversationHover(id) {
      document.getElementById('mailbox-remove-' + id).style.display = 'block'
      document.getElementById('mailbox-date-' + id).style.display = 'none'
    },
    handleConversationLeave(id) {
      document.getElementById('mailbox-remove-' + id).style.display = 'none'
      document.getElementById('mailbox-date-' + id).style.display = 'block'
    },
    getBackendUrl() {
      return process.env.VUE_APP_BACKEND_APP_URL
    }
  }
};
</script>

<template>
  <Layout>
    <div class="mail-page">
      <PageHeader
        :title="title"
        subTitle=""
        :items="items"
        icon="fa fa-inbox" />
      <el-row
        :gutter="20"
        class="email-wrapper"
        id="email-wrapper">
        <el-col :md="4" class="p-0">
          <Sidepanel :tab="tab" :inboxTabs="inboxTabs"/>
        </el-col>
        <el-col :md="20">
          <div class="btn-toolbar p-2">
            <Toolbar />
          </div>
          <el-tabs
            id="message-section-tab"
            stretch
            type="border-card"
            v-model="tab"
            :before-leave="preventTabClick"
            @tab-click="handleTabClick"
            style="background: none;box-shadow: none;border: none;">
            <el-tab-pane
              v-for="_tab in tabs"
              :key="_tab.link"
              :active="_tab.active"
              :name="_tab.link"
            >
              <template #label>
                <a
                  :href="'/mail/' + _tab.link"
                  class="product-tab-link font-size-16">
                  <i :class="['font-size-16', _tab.icon]"></i>&nbsp;&nbsp;&nbsp;{{ _tab.value }}
                </a>
              </template>
              <div
                class="data-table"
                v-if="tab==_tab.link && ['inbox', 'order'].includes(tab)">
                <el-row :gutter="20">
                  <el-col :md="8" class="mail-list-container">
                    <Scroll-Div
                      :height="conversationListSectionHeight + 'px'"
                      width="100%"
                      class="conversationListSection">
                      <div
                        v-for="(email,index) in data"
                        :key="index"
                        :class="{ 'mail-list': true, 'unread': email.unread === true }"
                        :to="`/email/reademail/${email.id}`"
                        :id="'conversation-' + email.id"
                        @mouseover="handleConversationHover(email.id)"
                        @mouseleave="handleConversationLeave(email.id)"
                        @click="loadConversationMessages(email.id)"
                      >
                        <el-row
                          class="py-2 list-group-item px-0"
                          style="cursor: pointer;">
                          <el-col :md="2" class="text-center">
                            <el-checkbox v-model="checked"></el-checkbox>
                          </el-col>
                          <el-col :md="18">
                            <div class="sender-name">
                              <el-tag
                                type="item.type"
                                effect="dark"
                                size="mini">
                                Jewel Giant
                              </el-tag>
                              <span
                                class="mailbox-name name"
                                style="color: #409EFF; margin-left: 5px;">
                                {{email.fromEmail}}
                              </span>
                            </div>
                            <div class="message_text">
                              <div class="text-muted text-wrap" style="font-size: 13px;color: #444 !important">
                                <span class="badge badge-success offer-accept-badge" style="display: none">Approved</span>
                                <span class="badge badge-danger offer-decline-badge" style="display: none">Declined</span>
                                {{email.subject}}
                              </div>
                              <div
                                class="text-muted text-wrap"
                                style="font-size: 13px;">
                              {{ email.message }}
                              </div>
                            </div>
                          </el-col>
                          <el-col :md="4" class="text-center">
                            <span :id="'mailbox-date-' + email.id">{{ getFormattedDate(email.updatedAt) }}</span>
                            <span :id="'mailbox-remove-' + email.id" style="font-size: 14px;display: none">
                              <i class="fas fa-trash-alt" style="color: red !important;"></i>
                            </span>
                          </el-col>
                        </el-row>
                      </div>
                    </Scroll-Div>
                  </el-col>
                  <el-col
                    :md="16"
                    class="mail-view-container">
                    <div
                      v-for="(message, index) in messages" 
                      :key="index"
                      :class="'mail-view' + message.id"
                      style="border-bottom: 1px solid #eee">
                      <el-row class="pt-2" style="border-bottom: 1px solid #eee">
                        <el-col :md="4" class="pb-2 text-center">
                          <img
                            class="img-sm rounded-circle"
                            style="max-width: 80px;"
                            src="https://s3-us-west-2.amazonaws.com/ncmebay/1583797828or_1583705542_PngItem_5040528.png"
                            alt="">
                        </el-col>
                        <el-col :md="12">
                          <div class="details text-center">
                            <div class="msg-subject">
                              {{ message.CustomerConversation.subject }}
                            </div>
                            <div class="sender-email mb-0">
                              <a
                                href="javascript:;" 
                                class="btn-send-message-to-contact">{{ message.CustomerConversation.fromEmail }}</a>
                            </div>
                            <div class="">
                                <span style="font-size: .75rem;letter-spacing: .3px;color: #5f6368;font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;">
                                  {{ getFormattedDateFromFull(message.createdAt) }} ({{ getDateTimeAgo(message.createdAt) }})
                                </span>
                            </div>
                        </div>
                        </el-col>
                        <el-col
                          :md="8"
                          class="text-right pt-4">
                          <el-button size="mini"><i class="fas fa-reply"></i>&nbsp;&nbsp;Reply</el-button>
                          <el-button size="mini"><i class="far fa-trash-alt"></i>&nbsp;&nbsp;Delete</el-button>
                        </el-col>
                      </el-row>
                      <iframe
                        :src="getBackendUrl() + 'admin/messages/load/' + message.id"
                        style="border: none; overflow: hidden;width: 100%;"
                        frameborder="0"
                        scrolling="no"
                        id="iframe-content"
                        @load="onMessageLoad"></iframe>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </Layout>
</template>